exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-media-wp-post-slug-tsx": () => import("./../../../src/pages/media/{wpPost.slug}.tsx" /* webpackChunkName: "component---src-pages-media-wp-post-slug-tsx" */),
  "component---src-pages-nase-projekty-tsx": () => import("./../../../src/pages/nase-projekty.tsx" /* webpackChunkName: "component---src-pages-nase-projekty-tsx" */),
  "component---src-pages-podporujeme-tsx": () => import("./../../../src/pages/podporujeme.tsx" /* webpackChunkName: "component---src-pages-podporujeme-tsx" */),
  "component---src-pages-pro-investory-tsx": () => import("./../../../src/pages/pro-investory.tsx" /* webpackChunkName: "component---src-pages-pro-investory-tsx" */),
  "component---src-pages-pro-media-tsx": () => import("./../../../src/pages/pro-media.tsx" /* webpackChunkName: "component---src-pages-pro-media-tsx" */),
  "component---src-pages-projekt-wp-project-slug-tsx": () => import("./../../../src/pages/projekt/{wpProject.slug}.tsx" /* webpackChunkName: "component---src-pages-projekt-wp-project-slug-tsx" */),
  "component---src-pages-skupina-oblasti-investic-tsx": () => import("./../../../src/pages/skupina/oblasti-investic.tsx" /* webpackChunkName: "component---src-pages-skupina-oblasti-investic-tsx" */),
  "component---src-pages-skupina-tsx": () => import("./../../../src/pages/skupina.tsx" /* webpackChunkName: "component---src-pages-skupina-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */)
}

