import React from 'react'

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <noscript>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: `
              [data-sal|='fade'] {
                opacity: 1;
              }

              [data-sal|='slide'],
              [data-sal|='zoom'] {
                opacity: 1;
                transform: none;
              }

              [data-sal|='flip'] {
                transform: none;
              }
            `,
          }}
        />
      </noscript>
    </>
  )
}
